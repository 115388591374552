import http from "@/utils/http";

export default {
  namespaced: true,
  state: {
    captcha: [],
    userToken: {}
  },
  mutations: {
    getcaptcha(state, data) {
      state.captcha = data;
    },
    setUserToken(state, data) {
      state.userToken = data;
    }
  },
  getters: {},
  actions: {
    // 退出登录
    logout(context, data) {
      return http.post("/user/login/out", data);
    },
    // 用户登录
    userLogin(context, data) {
      return http.post("/user/login", data);
    },
    // 地址新增
    userAddress(context, data) {
      return http.post("/user/address", data);
    },
    // 用户注册
    userRegister(context, data) {
      return http.post("/user/register", data);
    },
    // 地址设置为默认使用
    userSetDefAddress(context, data) {
      return http.put(`/user/address/${data.id}`);
    },
    // 地址查询
    async getUserAddress({ commit }, data) {
      const result = await http.get(`/user/address/${data.id}`);
      if (!result) {
        return;
      }
      return result;
    },
    // 地址修改
    userPutAddress(context, data) {
      return http.put("/user/address", data);
    },
    // 地址删除
    userDelAddress(context, data) {
      return http.delete(`/user/address/${data.id}`);
    },
    // 用户地址
    async userAddressList({ commit }, data) {
      const result = await http.get("/user/address/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 发送短信
    async captcha({ commit }, data) {
      const result = await http.get("/edge/captcha", {
        params: data
      });
      if (!result) {
        return;
      }
      commit("getcaptcha", result.data);
    },
    
    // 短信验证码-校验
    async getValidate({ commit }, data) {
      const result = await http.get("/edge/sms/validate", {
        params: data
      });
      if (!result) {
        return;
      }
      commit("phoneValMuta", result.data);
    },
    // 退出登录
    logout(context, data) {
      return http.post("/user/login/out", data);
    }
  }
};
