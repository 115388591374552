// 商品首页
const Home = resolve => {
  import("@/views/shopping/Home").then(module => {
    resolve(module);
  });
};

// 商品详情页
const GoodsDetails = resolve => {
  import("@/views/shopping/GoodsDetails").then(module => {
    resolve(module);
  });
};

// 购物车
const Car = resolve => {
  import("@/views/shopping/Car").then(module => {
    resolve(module);
  });
};

// 确认订单
const ConfirmOrder = resolve => {
  import("@/views/shopping/ConfirmOrder").then(module => {
    resolve(module);
  });
};

// 支付完成
const Payment = resolve => {
  import("@/views/shopping/Payment").then(module => {
    resolve(module);
  });
};

// 搜索页
const Search = resolve => {
  import("@/views/shopping/Search").then(module => {
    resolve(module);
  });
};
// 搜索页
const SearchRes = resolve => {
  import("@/views/shopping/SearchRes").then(module => {
    resolve(module);
  });
};

// 快速下单
const Ordered = resolve => {
  import("@/views/shopping/Ordered").then(module => {
    resolve(module);
  });
};

const JcOrder = resolve => {
  import("@/views/shopping/JcOrder").then(module => {
    resolve(module);
  });
};

const Special = resolve => {
  import("@/views/shopping/Special").then(module => {
    resolve(module);
  });
};

const NewProduct = resolve => {
  import("@/views/shopping/NewProduct").then(module => {
    resolve(module);
  });
};


export default [
  {
    path: "",
    name: "Shopping",
    redirect: "home"
  },
  {
    path: "home",
    name: "ShoppingHome",
    component: Home,
    meta: {
      title: "全部商品",
      keepAlive: true,
    }
  },
  {
    path: 'newproduct',
    name: 'NewProduct',
    component: NewProduct,
    meta: {
      title: "新品需求",
    }
  },
  {
    path: 'special',
    name: 'Special',
    component: Special,
    meta: {
      title: "专题活动",
      keepAlive: true,
    }
  },
  {
    path: "jcorder",
    name: "jcorder",
    component: JcOrder,
    meta: {
      title: "常用清单",
      keepAlive: true,
    }
  },
  {
    path: "search",
    name: "search",
    component: Search,
    meta: {
      title: "搜索",
      keepAlive: true,
    }
  },
  {
    path: "ordered",
    name: "ordered",
    component: Ordered,
    meta: {
      title: "快速下单",
      // keepAlive: true,
    }
  },
  {
    path: "searchres",
    name: "searchres",
    component: SearchRes,
    meta: {
      title: "搜索商品",
      // keepAlive: true,
    }
  },
  {
    path: "payment",
    name: "Payment",
    component: Payment,
    meta: {
      title: "支付完成",
      white: true,
    }
  },
  {
    path: "confirmorder",
    name: "ConfirmOrder",
    component: ConfirmOrder,
    meta: {
      title: "确认订单",
    }
  },
  {
    path: "details",
    name: "GoodsDetails",
    component: GoodsDetails,
    meta: {
      title: "详情页",
    }
  },
  {
    path: "car",
    name: "car",
    component: Car,
    meta: {
      title: "购物车",
      // keepAlive: true,
    }
  },
]