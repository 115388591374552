import Vue from 'vue'
import Vuex from 'vuex'
import http from "@/utils/http";
import account from "./account";
import activity from "./activity";
import shopping from "./shopping";
import guide from "./guide";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 当前赛事信息
    matchCurrent: {},
    // 机构信息
    orgInfo: "",
    // 购物车的商品数量
    goodsNum: 0,
    // 登录背景图
    loginBg: "",
  },
  mutations: {
    orgInfoMuta(state, data) {
      const s = state;
      s.orgInfo = data;
    },
    loginMuta(state, data) {
      const s = state;
      s.loginBg = data;
    },
    matchCurrentMuta(state, data) {
      const s = state;
      s.matchCurrent = data;
    },
    goodsNumMuta(state, data) {
      const s = state;
      s.goodsNum = data;
    },
  },
  actions: {
    // 动态表单数据
    PostFormData(context,data) {
      return http.post("/dynamicFormData/pub", data);
    },
    // 收藏-添加
    addCollect(context, data) {
      return http.post("/collect", data);
    },
    // 收藏-取消
    cancelCollect(context, data) {
      return http.post("/collect/cancel", data);
    },
    // 获取当前赛区配置信息
    async getOrgInfo({ commit }, data) {
      const result = await http.get("/org/info", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 地区三级联动
    async getRegion({ commit }, data) {
      const result = await http.get("/region/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 广告
    async getAd({ commit }, data) {
      const result = await http.get("/banner/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 动态配置数据查询
    async variabledata({ commit }, data) {
      const result = await http.get("/sys/variabledata", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 字典列表查询
    async getDicList({ commit }, data) {
      const result = await http.get("/dictionary/data/list", {
        params: data
      });
      if (!result) {
        return;
      }
      return result;
    },
    // 微信分享
    async getWxConfig(context, data) {
      const result = await http.get("/wechat/config", {
        params: {
          url: location.href.split("#")[0] // 注：url传当前页面的url，不是要分享的url
        }
      });
      if (!result.data) return;
      let { ...obj } = { ...result.data };
      wx.config({
        debug: false,
        ...obj,
        jsApiList: [
          "updateTimelineShareData",
          "updateAppMessageShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
          // 'onMenuShareQQ',
          // 'onMenuShareQZone'
        ]
      });
      wx.ready(() => {
        wx.updateTimelineShareData(data);
        wx.updateAppMessageShareData(data);
        wx.onMenuShareTimeline(data);
        wx.onMenuShareAppMessage(data);
        // wx.onMenuShareQQ(data);
        // wx.onMenuShareQZone(data);
      });
    },
    // 文章详情并添加阅读数
    async getNewsDetails({ commit }, data) {
      let da = {};
      const result = await http.get(`/news/${data.id}`, {
        params: da
      });
      if (!result) {
        return;
      }
      return result;
    },
  },
  modules: {
    account,
    shopping,
    activity,
    guide,
  }
})
