<template>
  <div id="app" class="h5w">
    <transition name="fade">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
      </keep-alive>
    </transition>
    <transition name="fade">
      <router-view v-if='!$route.meta.keepAlive'></router-view>
    </transition>
  </div>
</template>
<script>
export default {

}
</script>

