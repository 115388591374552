// 登录
const Login = resolve => {
  import("@/views/guide/Login").then(module => {
    resolve(module);
  });
};

// 注册
const Register = resolve => {
  import("@/views/guide/Register").then(module => {
    resolve(module);
  });
};

// 忘记密码
const Forget = resolve => {
  import("@/views/guide/Forget").then(module => {
    resolve(module);
  });
};

// 协议
const Agree = resolve => {
  import("@/views/guide/Agree").then(module => {
    resolve(module);
  });
};

export default [
  {
    path: "",
    name: "Guide",
    redirect: "login"
  },
  {
    path: "login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
      white: true,
    }
  },
  {
    path: "register",
    name: "Register",
    component: Register,
    meta: {
      title: "注册账号",
      white: true,
    }
  },
  {
    path: "forget",
    name: "Forget",
    component: Forget,
    meta: {
      title: "忘记密码",
      white: true,
    }
  },
  {
    path: "agree",
    name: "Agree",
    component: Agree,
    meta: {
      title: "",
      white: true,
    }
  }
];
