import Vue from '@/utils/vueUse'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.prototype.$transObjUndefined = function(obj) {
  if (typeof obj === 'undefined' || obj === null) {
    return new Object();
  }
  return obj;
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
