import Vue from "vue";
import axios from "axios";
import conf from "@/config";
import router from "@/router";
import Cookies from "js-cookie";
import { isWeiXin, authFun, noAuthConfig } from "@/utils/common";

// 创建 axios 实例
const http = axios.create({
  baseURL: conf.apiURL + "/api",
  // timeout: 5000
});

// 发送请求拦截处理
http.interceptors.request.use(
  request => {
    const newRequest = request;
    let token = "";
    if (Cookies.get("user")) {
      token = JSON.parse(Cookies.get("user")).token;
    }
    newRequest.headers.token = token;
    return newRequest;
  },
  error => {
    if (error.response) {
      let data = error.response.data;
      let status = error.response.status;
      let statusText = error.response.statusText;
      if (typeof data == "undefined") {
        data = {};
        data.message = "";
      }
      if (!data.message) {
        data.message = error.message;
      }
      return Promise.reject({ data, status, statusText });
    } else {
      let data = {};
      data.message = "请求超时";
      let status = 408;
      let statusText = "请求超时";
      return Promise.reject({ data, status, statusText });
    }
  }
);

// 返回拦截处理
http.interceptors.response.use(
  response => {
    let data = response.data;
    let status = response.status;
    let statusText = response.statusText;
    return Promise.resolve({ data, status, statusText });
  },
  error => {
    if (error.response === undefined) {
      // Vue.prototype.$toast("系统更新");
      return false;
      router.push(
        `/guide/login?url=${encodeURIComponent(
          router.history.current.fullPath
        )}`
      );
    }
    let data = error.response.data;
    let status = error.response.status;
    let statusText = error.response.statusText;
    Vue.prototype.$Indicator.close();
    if (status === 400 && data.errorCode == 103) {
      // 用户请求的时候状态103不默认显示提示层
    } else if (
      (status === 400 && !(data.errorCode && data.errorCode === 100)) ||
      status === 500
    ) {
      Vue.prototype.$toast(data.message);
    } else {
      Cookies.remove("user");

      const fpath = router.history.current.fullPath;
      if (router.history.current.path === "/guide/login") {
        router.push(fpath);
      } else if ((data.errorCode === 501 || status === 401) && isWeiXin()) {
        const u = window.location.origin + fpath;
        if (!noAuthConfig()) {
          authFun(u);
        } else {
          router.push(`/guide/login?url=${encodeURIComponent(fpath)}`);
        }
      } else if (status == 401) {
        router.push(`/guide/login?url=${encodeURIComponent(fpath)}`);
      } else if (data.errorCode === 501) {
        Vue.prototype.$toast("系统更新");
        router.push(`/guide/login?url=${encodeURIComponent(fpath)}`);
      }
    }
    return Promise.reject({ data, status, statusText });
  }
);

export default http;
