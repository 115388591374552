// 个人中心
const Home = resolve => {
  import("@/views/account/Home").then(module => {
    resolve(module);
  });
};

// 我的订单
const MyOrder = resolve => {
  import("@/views/account/MyOrder").then(module => {
    resolve(module);
  });
};

// 订单详情
const OrderDetails = resolve => {
  import("@/views/account/OrderDetails").then(module => {
    resolve(module);
  });
};

// 个人资料
const Personal = resolve => {
  import("@/views/account/Personal").then(module => {
    resolve(module);
  });
};

// 绑定手机
const BindPhone = resolve => {
  import("@/views/account/BindPhone").then(module => {
    resolve(module);
  });
};

// 地址管理
const addressIndex = resolve => {
  import("@/views/account/address/Index.vue").then(module => {
    resolve(module);
  });
};

// 地址编辑
const Edit = resolve => {
  import("@/views/account/address/Edit.vue").then(module => {
    resolve(module);
  });
};

// 我的收藏
const Collection = resolve => {
  import("@/views/account/Collection.vue").then(module => {
    resolve(module);
  });
};

// 招商加盟
const Cooperation = resolve => {
  import("@/views/account/Cooperation.vue").then(module => {
    resolve(module);
  });
};

// 招商加盟
const Feedback = resolve => {
  import("@/views/account/Feedback.vue").then(module => {
    resolve(module);
  });
};

// 优惠券
const Coupons = resolve => {
  import("@/views/account/Coupons.vue").then(module => {
    resolve(module);
  });
};

// 平台优惠券
const PtCoupons = resolve => {
  import("@/views/account/PtCoupons.vue").then(module => {
    resolve(module);
  });
};

// 足迹
const FootPrint = resolve => {
  import("@/views/account/FootPrint.vue").then(module => {
    resolve(module);
  });
};

// 修改手机号码
const UpdatePhone = resolve => {
  import("@/views/account/UpdatePhone").then(module => {
    resolve(module);
  });
};

// 修改密码
const UpdatePwd = resolve => {
  import("@/views/account/UpdatePwd").then(module => {
    resolve(module);
  });
};

// 余额明细
const BalanceDetails = resolve => {
  import("@/views/account/BalanceDetails").then(module => {
    resolve(module);
  });
};

// 押金明细
const Deposit = resolve => {
  import("@/views/account/Deposit").then(module => {
    resolve(module);
  });
};


export default [
  {
    path: "",
    name: "Account",
    redirect: "home"
  },
  {
    path: "deposit",
    name: "deposit",
    component: Deposit,
    meta: {
      title: "押金明细",
      auth: true,
    }
  },
  {
    path: "balance",
    name: "balance",
    component: BalanceDetails,
    meta: {
      title: "余额明细",
      auth: true,
    }
  },
  {
    path: "footprint",
    name: "FootPrint",
    component: FootPrint,
    meta: {
      title: "足迹",
      auth: true,
    }
  },
  {
    path: "updatephone",
    name: "UpdatePhone",
    component: UpdatePhone,
    meta: {
      title: "修改手机号码",
      auth: true,
      white: true,
    }
  },
  {
    path: "updatepwd",
    name: "UpdatePwd",
    component: UpdatePwd,
    meta: {
      title: "修改密码",
      white: true,
      auth: true
    }
  },
  {
    path: "coupons",
    name: "coupons",
    component: Coupons,
    meta: {
      title: "我的优惠券",
      auth: true,
    }
  },
  {
    path: "ptcoupons",
    name: "ptcoupons",
    component: PtCoupons,
    meta: {
      title: "优惠券",
      auth: true,
    }
  },
  {
    path: "feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      title: "反馈内容",
      white: true,
      auth: true,
    }
  },
  {
    path: "cooperation",
    name: "cooperation",
    component: Cooperation,
    meta: {
      title: "合作",
      white: true,
      
    }
  },
  {
    path: "edit",
    name: "edit",
    component: Edit,
    meta: {
      title: "新增地址",
      white: true,
      auth: true,
    }
  },
  {
    path: "collection",
    name: "Collection",
    component: Collection,
    meta: {
      title: "我的收藏",
      auth: true,
    }
  },

  {
    path: "home",
    name: "AccountHome",
    component: Home,
    meta: {
      title: "个人中心",
      auth: true,
    }
  },
  {
    path: "addressindex",
    name: "addressindex",
    component: addressIndex,
    meta: {
      title: "地址管理",
      auth: true,
      keepAlive : true 
    }
  },
  {
    path: "bindphone",
    name: "bindPhone",
    component: BindPhone,
    meta: {
      title: "绑定手机",
      white: true,
      auth: true,
    }
  },
  {
    path: "personal",
    name: "Personal",
    component: Personal,
    meta: {
      title: "个人资料",
      white: true,
      auth: true,
    }
  },
  {
    path: "myorder",
    name: "MyOrder",
    component: MyOrder,
    meta: {
      title: "我的订单",
      auth: true,
    }
  },
  {
    path: "orderdetails",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      title: "订单详情",
      auth: true,
    }
  },
]